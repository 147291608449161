import {concatUnique} from '../../lib/array';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ROUTES} from '../../constants/navigation';
import {getRelativePath} from '../../lib/url';

/**
 * @author fero
 */

/*
* Base data key that are needed for every page.
*/
const BASE_GLOBAL_DATA = [
    GLOBAL_DATA.MANUFACTURERS,
    GLOBAL_DATA.COUNTRIES,
    GLOBAL_DATA.CUSTOMERS,
    GLOBAL_DATA.EMPLOYEES,
    GLOBAL_DATA.FLAGS,
    GLOBAL_DATA.CART_CURRENT,
    GLOBAL_DATA.ACCOUNT_INFO,
    GLOBAL_DATA.REQUEST_STATES,
    GLOBAL_DATA.QUOTATION_STATES,
    GLOBAL_DATA.QUOTATION_DIVISIBILITY_LEVELS,
    GLOBAL_DATA.ORDER_STATES,
    GLOBAL_DATA.FORMS,
    GLOBAL_DATA.PARAMETERS,
    GLOBAL_DATA.CATEGORY_TREE,
    GLOBAL_DATA.DISCOUNT_TREE,
    GLOBAL_DATA.TRANSFER_TYPES,
    GLOBAL_DATA.LANGUAGES,
    GLOBAL_DATA.CHANNELS,  
    GLOBAL_DATA.STOCKS,
    GLOBAL_DATA.CURRENCIES,
    GLOBAL_DATA.DEPARTMENTS,
    GLOBAL_DATA.EVENT_TYPES,
    GLOBAL_DATA.VEHICLES,
    GLOBAL_DATA.CUSTOMER_GROUPS,
];

/*
* Specifies data keys that should be fetched after navigation to given path. Fetch is done by data definition
* with given data key.
*/
export const routeGlobalDataKeys = (location, withBaseData) => {
    const fullPath = getRelativePath(location.pathname);
    let dataKeys = [];
    switch(fullPath) {
        case ROUTES.HOME:
            dataKeys = [
                GLOBAL_DATA.CATEGORIES,
                GLOBAL_DATA.PRODUCTS,
                GLOBAL_DATA.CATEGORY_NEWS,
                GLOBAL_DATA.CATEGORY_DETAILS,
            ];
            break;
        case ROUTES.PRODUCT_DETAILS:
            dataKeys = [
                GLOBAL_DATA.PRODUCT_DETAILS,
                GLOBAL_DATA.PRODUCT_RELATIVES,
            ];
            break;
        case ROUTES.CONTRACT:
            dataKeys = [
                GLOBAL_DATA.CONTRACT_PRODUCTS,
            ];
            break;
        case ROUTES.ASSIGNMENTS:
            dataKeys = [
                GLOBAL_DATA.TASKS,
            ];
            break;
        case ROUTES.DASHBOARD:
            dataKeys = [
                GLOBAL_DATA.DASHBOARD_ITEMS,
            ];
            break;
        case ROUTES.DISCOUNT_RULES:
            dataKeys = [
                GLOBAL_DATA.DISCOUNT_RULES,
            ];
            break;
        case ROUTES.ACCOUNT:
            dataKeys = [
                GLOBAL_DATA.ACCOUNT_ADDRESSES,
            ];
            break;
        case ROUTES.CUSTOMERS:
            dataKeys = [
                GLOBAL_DATA.CUSTOMER_LIST,
                GLOBAL_DATA.USERS,
                GLOBAL_DATA.CUSTOMER_TYPES,
            ];
            break;
        case ROUTES.CUSTOMER_DETAILS:
            dataKeys = [
                GLOBAL_DATA.CUSTOMER_DETAILS,
                GLOBAL_DATA.CUSTOMER_TYPES,
                GLOBAL_DATA.CUSTOMER_DETAILS_ADDRESSES,
                GLOBAL_DATA.CUSTOMER_DETAILS_USERS,
            ];
        break;
        case ROUTES.CUSTOMERS_USER_DETAILS:
            dataKeys = [
                GLOBAL_DATA.USER_DETAILS,
                GLOBAL_DATA.USER_DETAILS_ADDRESSES,
                GLOBAL_DATA.SUPERVISORS,
            ];
            break;
        case ROUTES.CART:
            dataKeys = [
                GLOBAL_DATA.CART_CURRENT,
                GLOBAL_DATA.CART_CUSTOMER_DETAILS,
                GLOBAL_DATA.ACCOUNT_ADDRESSES,
                GLOBAL_DATA.COMPANY_ADDRESSES,
                GLOBAL_DATA.PREDICTION_METHODS,
            ];
            break;
        case ROUTES.ORDERS:
            dataKeys = [
                GLOBAL_DATA.ORDERS,
            ];
            break;
        case ROUTES.ORDER_LINES:
            dataKeys = [
                GLOBAL_DATA.ORDER_LINES,
                GLOBAL_DATA.ORDER_LINE_STATES,
            ];
            break;
        case ROUTES.NEW_ORDER:
            dataKeys = [
                GLOBAL_DATA.ACCOUNT_ADDRESSES,
                GLOBAL_DATA.COMPANY_ADDRESSES,
            ];
            break;
        case ROUTES.ORDER_DETAILS:
            dataKeys = [
                GLOBAL_DATA.ORDER_DETAILS,
                GLOBAL_DATA.ORDER_LINE_STATES,
                GLOBAL_DATA.ACCOUNT_ADDRESSES,
                GLOBAL_DATA.COMPANY_ADDRESSES,
            ];
            break;
        case ROUTES.QUOTATIONS:
            dataKeys = [
                GLOBAL_DATA.QUOTATIONS,
            ];
            break;
        case ROUTES.QUOTATION_DETAILS:
            dataKeys = [
                GLOBAL_DATA.QUOTATION_DETAILS,
            ];
            break;
        case ROUTES.INVOICES:
            dataKeys = [
                GLOBAL_DATA.INVOICES,
                GLOBAL_DATA.INVOICE_CLASSES,
            ];
            break;
        case ROUTES.REQUESTS:
            dataKeys = [
                GLOBAL_DATA.REQUESTS,
            ];
            break;
        case ROUTES.REQUEST_DETAILS:
            dataKeys = [
                GLOBAL_DATA.REQUEST_DETAILS,
            ];
            break;
        case ROUTES.QUOTATION_MAKE_ORDER:
            dataKeys = [
                GLOBAL_DATA.ACCOUNT_ADDRESSES,
                GLOBAL_DATA.QUOTATION_DETAILS,
                GLOBAL_DATA.COMPANY_ADDRESSES,
            ];
            break;
        case ROUTES.NEWS:
            dataKeys = [
                GLOBAL_DATA.NEWS,
            ];
            break;
        case ROUTES.NEWS_DETAIL:
            dataKeys = [
                GLOBAL_DATA.NEWS,
            ];
            break;
        case ROUTES.SHOPPING_LISTS:
            dataKeys = [
                GLOBAL_DATA.SHOPPING_LISTS,
            ];
            break;
        case ROUTES.REPORTS:
            dataKeys = [
                GLOBAL_DATA.REPORTS,
            ];
            break;
        case ROUTES.REPORT_MANAGE:
            dataKeys = [
                GLOBAL_DATA.REPORTS,
            ];
            break;
        case ROUTES.REPORT_DISPLAY:
            dataKeys = [
                GLOBAL_DATA.REPORTS,
            ];
            break;
        case ROUTES.ANALYSIS:
            dataKeys = [
                GLOBAL_DATA.ANALYSIS_TYPES,
            ];
            break;
        case ROUTES.EVENTS:
            dataKeys = [
                GLOBAL_DATA.EVENTS,
                GLOBAL_DATA.VEHICLES,
            ];
            break;
        case ROUTES.ALERTS:
            dataKeys = [
                GLOBAL_DATA.ALERT_TYPES,
                GLOBAL_DATA.ALERTS,
            ];
            break;
        case ROUTES.ADMINISTRATION_INVENTORY_CLASSES:
            dataKeys = [
                GLOBAL_DATA.INVENTORY_CLASSES,
            ];
            break;
        case ROUTES.ADMINISTRATION_BACKUP:
            dataKeys = [
                GLOBAL_DATA.BACKUP_STATUS,
            ];
            break;

        case ROUTES.ADMINISTRATION_SETTINGS:
            dataKeys = [
                GLOBAL_DATA.SETTINGS,
            ];
            break;

        case ROUTES.ADMINISTRATION_LICENSE:
        case ROUTES.SUBSCRIPTIONS:
            dataKeys = [
                GLOBAL_DATA.LICENSE_MODULES,
            ];
            break;
    }
    if(withBaseData) {
        dataKeys = concatUnique(dataKeys, BASE_GLOBAL_DATA);
    }
    return dataKeys;
};