import {QUERY_PARAMS} from '../../constants/navigation'
import { TRADE } from '../../constants/trade';
import {getQueryParam, getQueryParamNumber} from '../../lib/url';

/**
 * This file contains functions which create objects with attributes for fetch. These attributes are usually retrieved
 * from location's search part.
 *
 * @author fero
 */

export function fetchProductsAttributes(location) {
    return {
        id_product_group: getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP),
        search_type: getQueryParam(location, QUERY_PARAMS.SEARCH_TYPE),
        designation: getQueryParam(location, QUERY_PARAMS.DESIGNATION),
        id_manufacturer: getQueryParam(location, QUERY_PARAMS.ID_MANUFACTURER),
        availability: getQueryParam(location, QUERY_PARAMS.PRODUCTS_AVAILABILITY),
        id_ref_customer: getQueryParam(location, QUERY_PARAMS.ID_REF_CUSTOMER),
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
        limit: getQueryParam(location, QUERY_PARAMS.PRODUCTS_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.PRODUCTS_OFFSET),
        order: getQueryParam(location, QUERY_PARAMS.PRODUCTS_ORDER_BY),
        parameters: getQueryParam(location, QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS),
        no_category_limit: getQueryParam(location, QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY),
        show_filters: getQueryParam(location, QUERY_PARAMS.PRODUCTS_SHOW_FILTERS),
        is_visible: getQueryParam(location, QUERY_PARAMS.PRODUCTS_IS_VISIBLE)
    };
};

export function fetchProductDetailsAttributes(location) {
     return {
        id: getQueryParam(location, QUERY_PARAMS.ID_PRODUCT),
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
    };
};

export function fetchCartCurrentAttributes(location) {
    return {
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
        id_transport_type: getQueryParam(location, QUERY_PARAMS.ID_TRANSPORT_TYPE),
        id_payment_method: getQueryParam(location, QUERY_PARAMS.ID_PAYMENT_METHOD),
        order: getQueryParam(location, QUERY_PARAMS.CART_ORDER_BY),
    };
};

export function fetchContractProductsAttributes(location) {
    return {
        only_special_price: 1,
        search_type: getQueryParam(location, QUERY_PARAMS.CONTRACT_SEARCH_TYPE),
        designation: getQueryParam(location, QUERY_PARAMS.CONTRACT_DESIGNATION),
        id_manufacturer: getQueryParam(location, QUERY_PARAMS.CONTRACT_ID_MANUFACTURER),
        availability: getQueryParam(location, QUERY_PARAMS.CONTRACT_AVAILABILITY),
        id_ref_customer: getQueryParam(location, QUERY_PARAMS.CONTRACT_ID_REF_CUSTOMER),
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
        limit: getQueryParam(location, QUERY_PARAMS.CONTRACT_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.CONTRACT_OFFSET),
        order: getQueryParam(location, QUERY_PARAMS.CONTRACT_ORDER_BY),
    };
};

export function fetchDocumentsAttributes(location) {
    return {
        
    };
}

export function fetchCartCustomerDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
    };
};

export function fetchUserDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.USER_DETAILS_ID_USER),
        show_api_key: getQueryParam(location, QUERY_PARAMS.SHOW_API_KEY),
    };
};

export function fetchUserCurrentAttributes(location) {
    return {
        show_api_key: getQueryParam(location, QUERY_PARAMS.SHOW_API_KEY),
        show_details: 1
    };
};

export function fetchUserDetailsAddressesAttributes(location) {
    return {
        id_user: getQueryParam(location, QUERY_PARAMS.USER_DETAILS_ID_USER),
        show_hidden: getQueryParam(location, QUERY_PARAMS.ADDRESSES_SHOW_HIDDEN),
    };
};

export function fetchUsersAttributes(location) {
    return {
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER),
        phrase: getQueryParam(location, QUERY_PARAMS.CUSTOMER_USER_PHRASE),
        id_customer_type: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_TYPE),
        id_salesman: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN2),
        auto_invoice: getQueryParam(location, QUERY_PARAMS.CUSTOMER_AUTO_INVOICE),
        payment_due: getQueryParam(location, QUERY_PARAMS.CUSTOMER_PAYMENT_DUE),
        id_group: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_GROUP),
        newsletter: getQueryParam(location, QUERY_PARAMS.USERS_NEWSLETTER),
        order: getQueryParam(location, QUERY_PARAMS.USERS_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.USERS_LIMIT),
    };
};

export function fetchCustomerDetailAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER),
    };
};

export function fetchCustomerDetailsAddressesAttributes(location) {
    return {
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER),
        show_hidden: getQueryParam(location, QUERY_PARAMS.ADDRESSES_SHOW_HIDDEN),
    };
};

export function fetchCustomerDetailsUsersAttributes(location) {
    return {
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_PAGE_CUSTOMER),
        limit: getQueryParam(location, QUERY_PARAMS.CUSTOMER_USERS_LIMIT),
        order: getQueryParam(location, QUERY_PARAMS.CUSTOMER_USERS_ORDER_BY),
    };
};

export function fetchCustomerListAttributes(location) {
    return {
        show_hidden: getQueryParam(location, QUERY_PARAMS.CUSTOMER_SHOW_HIDDEN),
        phrase: getQueryParam(location, QUERY_PARAMS.CUSTOMER_USER_PHRASE),
        id_type: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_TYPE),
        id_group: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_GROUP),
        id_salesman: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.CUSTOMER_ID_SALESMAN2),
        auto_invoice: getQueryParam(location, QUERY_PARAMS.CUSTOMER_AUTO_INVOICE),
        payment_due: getQueryParam(location, QUERY_PARAMS.CUSTOMER_PAYMENT_DUE)
    };
};

export function fetchDiscountRulesAttributes(location) {
    return {
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
        phrase: getQueryParam(location, QUERY_PARAMS.DISCOUNT_RULES_PHRASE),
    };
};

export function fetchOrderAllAttributes(location) {
    return {
        trade: getQueryParam(location, QUERY_PARAMS.ORDER_TRADE),
        dealer_reference: getQueryParam(location, QUERY_PARAMS.ORDER_REF),
        customer_reference: getQueryParam(location, QUERY_PARAMS.ORDER_CUSTOMER_REFERENCE),
        deal_code: getQueryParam(location, QUERY_PARAMS.ORDER_DEAL_CODE),
        id_status: getQueryParam(location, QUERY_PARAMS.ORDER_ID_STATUS),
        id_customer: getQueryParam(location, QUERY_PARAMS.ORDER_ID_CUSTOMER),
        id_dealer: getQueryParam(location, QUERY_PARAMS.ORDER_ID_DEALER),
        id_salesman: getQueryParam(location, QUERY_PARAMS.ORDER_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.ORDER_ID_SALESMAN2),
        id_user: getQueryParam(location, QUERY_PARAMS.ORDER_ID_USER),
        sent_at: getQueryParam(location, QUERY_PARAMS.ORDER_CREATED_AT),
        last_change: getQueryParam(location, QUERY_PARAMS.ORDER_LAST_CHANGE),
        date_requested: getQueryParam(location, QUERY_PARAMS.ORDER_DATE_REQUESTED),
        notices: getQueryParam(location, QUERY_PARAMS.ORDER_NOTICES),
        designation: getQueryParam(location, QUERY_PARAMS.ORDER_DESIGNATION),
        id_department: getQueryParam(location, QUERY_PARAMS.ORDER_DEPARTMENT),
        show_remote: getQueryParamNumber(location, QUERY_PARAMS.ORDER_SHOW_REMOTE) ? 1 : 0,
        order: getQueryParam(location, QUERY_PARAMS.ORDER_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.ORDER_LIMIT),
    };
};

export function fetchOrderLinesAttributes(location) {
    return {
        trade: getQueryParam(location, QUERY_PARAMS.ORDER_TRADE),
        order_reference: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ORDER_REFERENCE),
        order_customer_reference: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_CUSTOMER_REFERENCE),
        id_status: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ID_STATUS),
        sent_at: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_SENT_AT),
        requested_at: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_REQUESTED_AT),
        available_at: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_AVAILABLE_AT),
        designation: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_DESIGNATION),
        id_manufacturer: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ID_MANUFACTURER),
        id_customer: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ID_CUSTOMER),
        order: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.ORDER_LINES_LIMIT),
    };
};

export function fetchOrderDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_ORDER),
        id_status: getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_STATUS),
        designation: getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_PHRASE),
        order: getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_OFFSET),
    };
};

export function fetchQuotationAllAttributes(location) {
    return {
        trade: getQueryParam(location, QUERY_PARAMS.QUOTATION_TRADE),
        id_customer: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_CUSTOMER),
        id_dealer: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_DEALER),
        id_salesman: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_SALESMAN2),
        dealer_reference: getQueryParam(location, QUERY_PARAMS.QUOTATION_REF),
        customer_reference: getQueryParam(location, QUERY_PARAMS.QUOTATION_CUSTOMER_REF),
        request_reference: getQueryParam(location, QUERY_PARAMS.QUOTATION_REQUEST_REF),
        notices: getQueryParam(location, QUERY_PARAMS.QUOTATION_NOTICES),
        sent_at: getQueryParam(location, QUERY_PARAMS.QUOTATION_SENT_AT),
        last_change: getQueryParam(location, QUERY_PARAMS.QUOTATION_LAST_CHANGE),
        active_to: getQueryParam(location, QUERY_PARAMS.QUOTATION_ACTIVE_TO),
        id_user: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_USER),
        id_status: getQueryParam(location, QUERY_PARAMS.QUOTATION_ID_STATUS),
        order: getQueryParam(location, QUERY_PARAMS.QUOTATION_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.QUOTATION_LIMIT),
    };
};

export function fetchQuotationDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_QUOTATION),
        designation: getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_PHRASE),
        order: getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_OFFSET),
    };
};

export function fetchInvoiceAllAttributes(location) {
    return {
        trade: TRADE.SELL,
        hide_overdue_proformas: 1,
        sent_at: getQueryParam(location, QUERY_PARAMS.INVOICE_SENT_AT),
        transfered_at: getQueryParam(location, QUERY_PARAMS.INVOICE_TRANSFERED_AT),
        invoiced_at: getQueryParam(location, QUERY_PARAMS.INVOICE_AT),
        payment_due: getQueryParam(location, QUERY_PARAMS.INVOICE_PAYMENT_DUE),
        is_overdue: getQueryParam(location, QUERY_PARAMS.INVOICE_IS_OVERDUE),
        is_paid: getQueryParam(location, QUERY_PARAMS.INVOICE_IS_PAID),
        dealer_reference: getQueryParam(location, QUERY_PARAMS.INVOICE_REF),
        class: getQueryParam(location, QUERY_PARAMS.INVOICE_CLASS),
        id_customer: getQueryParam(location, QUERY_PARAMS.INVOICE_ID_CUSTOMER),
        id_user: getQueryParam(location, QUERY_PARAMS.INVOICE_ID_USER),
        id_salesman: getQueryParam(location, QUERY_PARAMS.INVOICE_ID_SALESMAN),
        order: getQueryParam(location, QUERY_PARAMS.INVOICE_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.INVOICE_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.INVOICE_OFFSET),
    };
};

export function fetchRequestAllAttributes(location) {
    return {
        trade: getQueryParam(location, QUERY_PARAMS.REQUEST_TRADE),
        id_customer: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_CUSTOMER),
        id_dealer: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_DEALER),
        id_salesman: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_SALESMAN2),
        id_user: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_USER),
        id_status: getQueryParam(location, QUERY_PARAMS.REQUEST_ID_STATUS),
        sent_at: getQueryParam(location, QUERY_PARAMS.REQUEST_CREATED_AT),
        dealer_reference: getQueryParam(location, QUERY_PARAMS.REQUEST_REF),
        customer_reference: getQueryParam(location, QUERY_PARAMS.REQUEST_CUSTOMER_REF),
        notices: getQueryParam(location, QUERY_PARAMS.REQUEST_NOTICES),
        order: getQueryParam(location, QUERY_PARAMS.REQUEST_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.REQUEST_LIMIT),
    };
};

export function fetchRequestDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_REQUEST),
        designation: getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_PHRASE),
        order: getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_LIMIT),
        offset: getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_OFFSET),
    };
};

export function fetchNewsAllAttributes(location) {
    const showHidden = getQueryParam(location, QUERY_PARAMS.NEWS_SHOW_HIDDEN);
    return {
        id_product_group: getQueryParam(location, QUERY_PARAMS.NEWS_ID_PRODUCT_GROUP),
        phrase: getQueryParam(location, QUERY_PARAMS.NEWS_PHRASE),
        id_author: getQueryParam(location, QUERY_PARAMS.NEWS_ID_AUTHOR),
        active_at: getQueryParam(location, QUERY_PARAMS.NEWS_ACTIVE_AT),
        last_change: getQueryParam(location, QUERY_PARAMS.NEWS_LAST_CHANGE),
        show_hidden: showHidden != null ? showHidden : 1,
        limit: getQueryParam(location, QUERY_PARAMS.NEWS_LIMIT),
    };
};

export function fetchCategoryNewsAttributes(location) {
    const productGroupId = getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP);
    return {
        id_product_group: productGroupId ? productGroupId : 'C' ,
    };
};

export function fetchCategoryDetailsAttributes(location) {
    const productGroupId = getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP);
    return {
        id: productGroupId ? productGroupId : 'C' ,
    };
};

export function fetchProductRelativesAttributes(location) {
    return {
        id_product: getQueryParam(location, QUERY_PARAMS.ID_PRODUCT),
        id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
    };
};

export function fetchFormDetailsAttributes(location) {
    return {
        id: getQueryParam(location, QUERY_PARAMS.ID_FORM),
    };
};

export function fetchReportsAttributes(location) {
    return {
        id_owner: getQueryParam(location, QUERY_PARAMS.REPORT_ID_OWNER),
        name: getQueryParam(location, QUERY_PARAMS.REPORT_NAME)
    };
};

export function fetchHistoryRecordsAttributes(location) {
    return {
        class: getQueryParam(location, QUERY_PARAMS.HISTORY_CLASS),
        method: getQueryParam(location, QUERY_PARAMS.HISTORY_METHOD),
    };
};

export function fetchEventsAttributes(location) {
    return {
        id_type: getQueryParam(location, QUERY_PARAMS.EVENT_ID_TYPE),
        id_user: getQueryParam(location, QUERY_PARAMS.EVENT_ID_USER),
        id_worker: getQueryParam(location, QUERY_PARAMS.EVENT_ID_WORKER),
        id_customer: getQueryParam(location, QUERY_PARAMS.EVENT_ID_CUSTOMER),
        id_vehicle: getQueryParam(location, QUERY_PARAMS.EVENT_ID_VEHICLE),
        start_at: getQueryParam(location, QUERY_PARAMS.EVENT_START_AT),
        has_refuel: getQueryParam(location, QUERY_PARAMS.EVENT_HAS_REFUEL),
        phrase: getQueryParam(location, QUERY_PARAMS.EVENT_PHRASE),
        order: getQueryParam(location, QUERY_PARAMS.EVENT_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.EVENT_LIMIT),
    };
};


export function fetchAlertsAttributes(location) {
    return {
        id_type: getQueryParam(location, QUERY_PARAMS.ALERT_ID_TYPE),
        id_salesman: getQueryParam(location, QUERY_PARAMS.ALERT_ID_SALESMAN),
        id_salesman2: getQueryParam(location, QUERY_PARAMS.ALERT_ID_SALESMAN2),
        id_customer: getQueryParam(location, QUERY_PARAMS.ALERT_ID_CUSTOMER),
        show_all: getQueryParam(location, QUERY_PARAMS.ALERT_SHOW_ALL),
        show_foreign: getQueryParam(location, QUERY_PARAMS.ALERT_SHOW_FOREIGN),
        tim: getQueryParam(location, QUERY_PARAMS.ALERT_TIM),
        class: getQueryParam(location, QUERY_PARAMS.ALERT_CLASS),
        phrase: getQueryParam(location, QUERY_PARAMS.ALERT_PHRASE),
        order: getQueryParam(location, QUERY_PARAMS.ALERT_ORDER_BY),
        limit: getQueryParam(location, QUERY_PARAMS.ALERT_LIMIT),
    };
};

export function fetchDepartmentsAttributes(location) {
    return {
        show_all: getQueryParam(location, QUERY_PARAMS.DEPARTMENT_SHOW_ALL),
    };
};

export function fetchDashBoardItemsAttributes(location) {
    return {
        id_user: getQueryParam(location, QUERY_PARAMS.DASHBOARD_ID_USER),
    };
};

export function fetchLicenseModulesAttributes(location) {
    return {
        phrase: getQueryParam(location, QUERY_PARAMS.LICENSE_PHRASE),
        id_customer: getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER),
    };
};