exports.components = {
  "component---src-pages-400-js": () => import("./../../../src/pages/400.js" /* webpackChunkName: "component---src-pages-400-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-backup-js": () => import("./../../../src/pages/admin/backup.js" /* webpackChunkName: "component---src-pages-admin-backup-js" */),
  "component---src-pages-admin-customer-groups-js": () => import("./../../../src/pages/admin/customerGroups.js" /* webpackChunkName: "component---src-pages-admin-customer-groups-js" */),
  "component---src-pages-admin-departments-js": () => import("./../../../src/pages/admin/departments.js" /* webpackChunkName: "component---src-pages-admin-departments-js" */),
  "component---src-pages-admin-event-types-js": () => import("./../../../src/pages/admin/eventTypes.js" /* webpackChunkName: "component---src-pages-admin-event-types-js" */),
  "component---src-pages-admin-flags-js": () => import("./../../../src/pages/admin/flags.js" /* webpackChunkName: "component---src-pages-admin-flags-js" */),
  "component---src-pages-admin-forms-js": () => import("./../../../src/pages/admin/forms.js" /* webpackChunkName: "component---src-pages-admin-forms-js" */),
  "component---src-pages-admin-history-js": () => import("./../../../src/pages/admin/history.js" /* webpackChunkName: "component---src-pages-admin-history-js" */),
  "component---src-pages-admin-import-export-js": () => import("./../../../src/pages/admin/importExport.js" /* webpackChunkName: "component---src-pages-admin-import-export-js" */),
  "component---src-pages-admin-inventory-classes-js": () => import("./../../../src/pages/admin/inventoryClasses.js" /* webpackChunkName: "component---src-pages-admin-inventory-classes-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-admin-license-js": () => import("./../../../src/pages/admin/license.js" /* webpackChunkName: "component---src-pages-admin-license-js" */),
  "component---src-pages-admin-settings-js": () => import("./../../../src/pages/admin/settings.js" /* webpackChunkName: "component---src-pages-admin-settings-js" */),
  "component---src-pages-admin-vehicles-js": () => import("./../../../src/pages/admin/vehicles.js" /* webpackChunkName: "component---src-pages-admin-vehicles-js" */),
  "component---src-pages-alerts-js": () => import("./../../../src/pages/alerts.js" /* webpackChunkName: "component---src-pages-alerts-js" */),
  "component---src-pages-analysis-js": () => import("./../../../src/pages/analysis.js" /* webpackChunkName: "component---src-pages-analysis-js" */),
  "component---src-pages-assignments-js": () => import("./../../../src/pages/assignments.js" /* webpackChunkName: "component---src-pages-assignments-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-category-add-js": () => import("./../../../src/pages/categoryAdd.js" /* webpackChunkName: "component---src-pages-category-add-js" */),
  "component---src-pages-category-edit-js": () => import("./../../../src/pages/categoryEdit.js" /* webpackChunkName: "component---src-pages-category-edit-js" */),
  "component---src-pages-claim-rules-js": () => import("./../../../src/pages/claimRules.js" /* webpackChunkName: "component---src-pages-claim-rules-js" */),
  "component---src-pages-commercial-terms-js": () => import("./../../../src/pages/commercialTerms.js" /* webpackChunkName: "component---src-pages-commercial-terms-js" */),
  "component---src-pages-contract-js": () => import("./../../../src/pages/contract.js" /* webpackChunkName: "component---src-pages-contract-js" */),
  "component---src-pages-customers-details-js": () => import("./../../../src/pages/customers/details.js" /* webpackChunkName: "component---src-pages-customers-details-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-customers-user-details-js": () => import("./../../../src/pages/customers/userDetails.js" /* webpackChunkName: "component---src-pages-customers-user-details-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-discount-rules-js": () => import("./../../../src/pages/discountRules.js" /* webpackChunkName: "component---src-pages-discount-rules-js" */),
  "component---src-pages-err-js": () => import("./../../../src/pages/err.js" /* webpackChunkName: "component---src-pages-err-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-order-js": () => import("./../../../src/pages/newOrder.js" /* webpackChunkName: "component---src-pages-new-order-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/orderDetails.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-order-lines-js": () => import("./../../../src/pages/orderLines.js" /* webpackChunkName: "component---src-pages-order-lines-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-personal-data-protection-js": () => import("./../../../src/pages/personalDataProtection.js" /* webpackChunkName: "component---src-pages-personal-data-protection-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product/details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-quotation-details-js": () => import("./../../../src/pages/quotationDetails.js" /* webpackChunkName: "component---src-pages-quotation-details-js" */),
  "component---src-pages-quotation-make-order-js": () => import("./../../../src/pages/quotationMakeOrder.js" /* webpackChunkName: "component---src-pages-quotation-make-order-js" */),
  "component---src-pages-quotations-js": () => import("./../../../src/pages/quotations.js" /* webpackChunkName: "component---src-pages-quotations-js" */),
  "component---src-pages-report-display-js": () => import("./../../../src/pages/report/display.js" /* webpackChunkName: "component---src-pages-report-display-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-report-manage-js": () => import("./../../../src/pages/report/manage.js" /* webpackChunkName: "component---src-pages-report-manage-js" */),
  "component---src-pages-request-details-js": () => import("./../../../src/pages/requestDetails.js" /* webpackChunkName: "component---src-pages-request-details-js" */),
  "component---src-pages-requests-js": () => import("./../../../src/pages/requests.js" /* webpackChunkName: "component---src-pages-requests-js" */),
  "component---src-pages-shopping-lists-js": () => import("./../../../src/pages/shoppingLists.js" /* webpackChunkName: "component---src-pages-shopping-lists-js" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */)
}

