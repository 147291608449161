import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../constants/globalData';
import generalFailedPC from '../fetch/generalFailedPC';
import Tooltip from '../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { EventObj } from '../../constants/propTypesDefinitions';
import { createFetchEventRemove } from '../../backend/apiCalls';

/**
 * @dusan
 */


 class EventRemove extends React.PureComponent {
    static propTypes = {
        eventObj: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
        type: PropTypes.string,
    };

    static defaultProps = {
        type: 'button'
    };

    render() {
        const {eventObj = {}, reload, type} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie eventu</Trans>}>
                    { type == 'link' ?
                        <div className="pl-2 link-button color-red"><Trans>Odstrániť</Trans></div>
                        : <Button className="mx-1" size="small" type="danger" icon="delete"/>
                    }
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Odstránenie eventu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť event?</Trans>,
                createFetchEventRemove(eventObj.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť event.`)}
        />;
    }

}

export default EventRemove;